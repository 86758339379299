
import  state  from "./moduleAccountManagerRateState";
import  mutations  from "./moduleAccountManagerRateMutations";
import  actions from "./moduleAccountManagerRateAction";
import getters from "./moduleAccountManagerRateGetters";


export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
