
export default {

  ADD_ITEM(state, item) {
    state.AccountManagerRates .unshift(item);
  },

  SET_AccountManager(state, AccountManagerRates ) {
    state.AccountManagerRates = AccountManagerRates
  },
};
