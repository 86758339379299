

<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
         ADD NEW
      </h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar
      class="scroll-area--data-list-add-new"
      :settings="settings"
    >
      <div class="p-6">
        <vs-input
          :label="$t('Comment')"
          v-model="AccountManager.Comment"
          class="mt-5 w-full"
          name="nameen"
          v-validate="'required'"
        />
      <span class="text-danger text-sm" v-show="errors.has('nameen')">{{
          errors.first("name_en")
        }}</span>

         <br><br>
              <span>Rate: </span>
          <br>
       <star-rating @rating-selected ="setRating">

       <template v-slot:screen-reader="slotProps"

       >
        This product has been rated {{slotProps.rating}} out of {{slotProps.stars}} stars
       </template>
         </star-rating>
      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">{{
        $t("Save")
      }}</vs-button>
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >{{ $t("Cancel") }}</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import StarRating from 'vue-star-rating';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true

    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
       this.AccountManager = JSON.parse(
          JSON.stringify(this.data)
        );

      }
    }
  },

  data() {
    return {
      rating: 0 ,
      PhotoPath: "",
      AccountManager:{},
      settings: {
        maxScrollbarLength: 30,
        wheelSpeed: 0.6
      }
    };
  },

  computed: {

    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.AccountManager;
    }
  },

  methods: {

    setRating(rating){
      this.AccountManager.Rate= rating;
    },
    initValues() {
      if (this.data.ID) return;
      this.AccountManager={};
    },

    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
            this.$store
              .dispatch("AccountManagerRateList/AddAgentPatientRate", this.AccountManager)
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    }
  },

  components: {
    VuePerfectScrollbar,
    StarRating
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
