

import axios from "@/axios.js";
import { reject, resolve } from 'core-js/fn/promise';


export default{


  GetAllAccountManagerRate({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("API/AccountManagerRate/GetAllAccountManagerRate")
        .then((response) => {
          commit('SET_AccountManager', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  AddAgentPatientRate({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("API/AccountManagerRate/AddAccountManagerRate", item)
        .then((response) => {

          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  ViewAccountManagerRateHistory({ commit }, AccountManagerID) {
      return new Promise((resolve, reject) => {
      axios.get("API/AccountManagerRate/ViewAccountManagerRateHistory?AccountManagerID="+ AccountManagerID)
        .then((response) => {
          commit('SET_AccountManager', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

};
